import React from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from "mobx";
import {IMaskInput} from 'react-imask';
import { BiMessageSquareError, BiCheckCircle, BiRefresh } from "react-icons/bi";
import { Accordion , Card, Modal, Button} from 'react-bootstrap';
import { PaymentMethodECheck } from './PaymentMethodECheck';
import { PaymentMethodsAccepted } from './PaymentMethodsAccepted';
import { SelectPaymentMethodSaved } from './SelectPaymentMethodSaved';
import { PaymentMethodCCForm } from './PaymentMethodCCForm';
import { AutopayForm } from './AutopayForm';
import { Message } from './Message';
import { EditCustomer } from './EditCustomer';
import NumberFormat from 'react-number-format';
import pointOfSale from '../assets/images/pointOfService.svg';
import achIcon from '../assets/images/ach.svg';
import cardIcon from '../assets/images/card.svg';

@inject('global' , 'vTerminal')
@observer
class VirtualTerminal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            infoModalIsOpen: true,
            searchLineItemPanelIsOpen: false,
            searchCustomerPanelIsOpen: false,
            newLineItemModalIsOpen: false,
            receiptModalIsOpen: false,
            confirmModalIsOpen: false,
            infoMessageInModal: '',
            customerToSearch: '',
            lineItemToSearch: '',
            searchingCustomer: false,
            searchingLineItem: false,
            lineItemTemplate: {
                name: '',
                type: '',
                label: '',
                value: '',
                description: '',
                quantity: 1,
                showDescription: true
            },
            lineItemTemplateLabelErrors: false,
            lineItemTemplateAmountErrors: false,
            paymentApproved: false,
            paymentResponseMessage: "",
            operation : "sale",
            responseList: [],
            swipeModalIsOpen: false,
            deviceDescription: '',
            inputQtyError: false,

            typing: false,
            typingTimeout: 0
        };
        this.renderAmountCategories = this.renderAmountCategories.bind(this);
        this.disableAutopay = this.disableAutopay.bind(this);
        this.handleCategoryTextChangeMask = this.handleCategoryTextChangeMask.bind(this);
        this.handleCategoryAmount = this.handleCategoryAmount.bind(this);
        this.setPaymentMethod = this.setPaymentMethod.bind(this);
        this.openInfoModal = this.openInfoModal.bind(this);
        this.closeNewLineItemModal = this.closeNewLineItemModal.bind(this);
        this.openNewLineItemModal = this.openNewLineItemModal.bind(this);
        this.closeInfoModal = this.closeInfoModal.bind(this);
        this.selectCustomer = this.selectCustomer.bind(this);
        this.removeAmountCategory = this.removeAmountCategory.bind(this);
        this.handleAmountCategoryType = this.handleAmountCategoryType.bind(this);
        this.handleCategoryTextChange = this.handleCategoryTextChange.bind(this);
        this.handleCategoryShowDescription = this.handleCategoryShowDescription.bind(this);
        this.addLineItem = this.addLineItem.bind(this);
        this.resetLineItemTemplate = this.resetLineItemTemplate.bind(this);
        this.makePayment = this.makePayment.bind(this);
        this.openSearchLineItemPanel = this.openSearchLineItemPanel.bind(this);
        this.closeSearchLineItemPanel = this.closeSearchLineItemPanel.bind(this);
        this.handleCategoryQtySubstract = this.handleCategoryQtySubstract.bind(this);
        this.handleCategoryQtyAdd = this.handleCategoryQtyAdd.bind(this);
        this.selectLineItem = this.selectLineItem.bind(this);
        this.clearCustomer = this.clearCustomer.bind(this);
        this.clearTextCustomer = this.clearTextCustomer.bind(this);
        this.reviewPayment = this.reviewPayment.bind(this);
        this.updateCustomerToSearch = this.updateCustomerToSearch.bind(this);
        this.savePaymentMethodForFuture = this.savePaymentMethodForFuture.bind(this);
        this.openReceiptModal = this.openReceiptModal.bind(this);
        this.closeReceiptModal = this.closeReceiptModal.bind(this);
        this.handleFirstCategoryTextChange = this.handleFirstCategoryTextChange.bind(this);
        this.handlePayorTextChange = this.handlePayorTextChange.bind(this);
        this.handleOperation = this.handleOperation.bind(this);
        this.handleChangeDeviceSelect = this.handleChangeDeviceSelect.bind(this);
        this.openSwipeModal = this.openSwipeModal.bind(this);
        this.closeSwipeModal = this.closeSwipeModal.bind(this);
        this.focus = this.focus.bind(this);
        this.renderPaymentMethodReceipt = this.renderPaymentMethodReceipt.bind(this);
        this.renderPaymentInformation = this.renderPaymentInformation.bind(this);
        this.init = this.init.bind(this);
        this.searchCustomerTyping = this.searchCustomerTyping.bind(this);
        this.goToInputError = this.goToInputError.bind(this);
    }

    handleChangeDeviceSelect(e){
        let value = e.target.value;
        let valueArray = value.split("|||");
        if(valueArray[0]){
            this.setState({deviceDescription:valueArray[1]});
            this.props.vTerminal.handleTextChangeACH(e.target.name, valueArray[0]);
        }
    }

    componentDidMount() {
        this.init();
    }

    init(){
        let globalConfig = this.props.pconfig;
        this.props.vTerminal.setConfig(globalConfig);
        this.setState({config: globalConfig});
        this.props.vTerminal.getPaymentPageFromApi().then(result => {

        })
        .catch(error => {
            console.error(error)
        });

        //devices
        this.props.vTerminal.getDevicesFromApi().then(res => {
        if(res && res.responseList){
            this.setState({responseList: res.responseList});
        }
        else{
            this.setState({responseList: []});
        }     
        })
        .catch(error => {
            console.error(error)
        });

        // default open (devices)
        if(globalConfig && globalConfig.defaultOpen && String(globalConfig.defaultOpen).toLowerCase() === "device"){
           this.props.vTerminal.setDefaultPaymentMethodActiveKey("3")
        }

        this.clearTextCustomer();
    }

    focus(e){
        e.target.focus();
        e.target.setSelectionRange(0, 1000000000);
    }
    
    handleOperation(e){
        this.props.vTerminal.setOperation(e.target.value);
    }

    updateCustomerToSearch(){
        this.setState({ customerToSearch: this.props.vTerminal.getSelectedCustomerString});
    }

    savePaymentMethodForFuture(e){
        this.props.vTerminal.savePaymentMethodForFuture(e.target.checked);
    }

    handleCategoryQtySubstract(event){
        this.props.vTerminal.handleCategoryQtySubstract(event);
    }

    handleCategoryQtyAdd(event){
        this.props.vTerminal.handleCategoryQtyAdd(event);
    }

    searchCustomerTyping(e){
        const self = this;

        if(e.target.value.length > 2){
            if (self.state.typingTimeout) {
                clearTimeout(self.state.typingTimeout);
            }

            self.setState({
                customerToSearch: e.target.value, 
                searchCustomerPanelIsOpen: true, 
                searchingCustomer: true,
                typing: false,
                typingTimeout: setTimeout(function () {
                    if(e.target.value.length > 2){
                        self.props.vTerminal.searchingCustomer(e.target.value).then(res => {
                            self.setState({ searchingCustomer: false });
                        })
                        .catch(error => {
                            console.error(error)
                            self.setState({ searchingCustomer: false });
                        });
                    }else{
                        self.setState({ searchingCustomer: false });
                    }
                }, 1000)
            });
        }
        else{
            this.setState({customerToSearch: e.target.value, searchCustomerPanelIsOpen: true});
            self.props.vTerminal.clearCustomer();
        }
    }
    
    clearTextCustomer(){
        this.setState({ customerToSearch: ''});
        this.props.vTerminal.clearCustomer();
        this.props.vTerminal.clearCustomerSelected();
        this.props.vTerminal.handleTextChangeACH('paymentMethodSaved', null);
    }
    

    searchLineItemTyping(e){
        const self = this;

        if(e.target.value.length > 2){
            if (self.state.typingTimeout) {
                clearTimeout(self.state.typingTimeout);
            }

            self.setState({
                lineItemToSearch: e.target.value,
                searchLineItemPanelIsOpen: true,
                searchingLineItem: true,
                typing: false,
                typingTimeout: setTimeout(function () {
                    if(e.target.value.length > 2){
                        self.props.vTerminal.searchingLineItems(e.target.value).then(res => {
                            self.setState({ searchingLineItem: false });
                        })
                        .catch(error => {
                            console.error(error)
                            self.setState({ searchingLineItem: false });
                        });
                    }else{
                        self.setState({ searchingLineItem: false });
                    }
                }, 1000)
            });
        }
        else{
            this.setState({lineItemToSearch: e.target.value, searchLineItemPanelIsOpen: true});
            this.props.vTerminal.clearLineItems();
        }
    }

    prevent(e) {
        e.preventDefault();
    }

    removeAmountCategory(event) {
        this.props.vTerminal.removeAmountCategory(event);
        if(this.props.vTerminal.amountCategories.length === 0){
            let emptyCategory = {
                name: "amountDefaultPayabliApp",
                type: 'customer',
                label: 'Amount you wish to charge',
                value: '0.00',
                description: '',
                quantity: 1,
                showDescription: true
            };
            this.props.vTerminal.setAmountCategories([emptyCategory]);
        }
        
    }

    selectCustomer(position,e){
        e.preventDefault();
        this.props.vTerminal.selectCustomer(position);
        this.setState({ customerToSearch: this.props.vTerminal.getSelectedCustomerString, searchCustomerPanelIsOpen: false }, function(){
            this.props.vTerminal.clearCustomer();

            if(this.props.vTerminal.getCustomerSelected){
                this.props.vTerminal.updateCustomerPaymentsOptions();
            }
            else{
                this.props.vTerminal.setPaymentMethod('card');
                this.props.vTerminal.setDefaultPaymentMethodActiveKey("0");
            }

            if(this.props.vTerminal.getCustomerSelected.StoredMethods && this.props.vTerminal.getCustomerSelected.StoredMethods.length !== 0){
                this.props.vTerminal.setPaymentMethod('');
                this.props.vTerminal.setPaymentMethodSaved(null);
            }
        });
    }

    selectLineItem(position,e){
        e.preventDefault();
        this.props.vTerminal.selectLineItem(position);
        this.setState({ searchLineItemPanelIsOpen: false , lineItemToSearch: ''}, function(){
            this.props.vTerminal.clearLineItems();
        });
    }

    openSearchLineItemPanel(){
        this.setState({ searchLineItemPanelIsOpen: true });
    }

    closeSearchLineItemPanel(){
        this.setState({ searchLineItemPanelIsOpen: false });
    }
    
    closeSearchLineItemPanelDelay(){
        let reactObj = this;
        setTimeout(function(){ 
            reactObj.setState({ searchLineItemPanelIsOpen: false });
        }, 500); 
    }
   
    closeSearchCustomerPanelDelay(){
        let reactObj = this;
        setTimeout(function(){ 
            reactObj.setState({ searchCustomerPanelIsOpen: false });

            if(Object.keys(reactObj.props.vTerminal.getCustomerSelected).length === 0){
                reactObj.clearTextCustomer();
            }
            
        }, 600); 
    }

    openInfoModal(info){
        this.setState({ infoModalIsOpen: false, infoMessageInModal: info });
    }

    clearCustomer(){
        this.props.vTerminal.clearCustomer();
    }

    closeInfoModal(){
        this.setState({ infoModalIsOpen: true });
    }
    
    closeReceiptModal(){

        this.init();
        this.setState({ receiptModalIsOpen: false });
        window.parent.postMessage("close-payabli-modal-vt", '*');
        this.props.vTerminal.resetPaymentPage();
        
    }
    
    openReceiptModal(){
        this.setState({ receiptModalIsOpen: true });
    }
    
    closeConfirmModal(){
        this.setState({ confirmModalIsOpen: false });
    }

    closeSwipeModal(){
        this.setState({ swipeModalIsOpen: false });
    }
    
    openSwipeModal(){
        this.setState({ swipeModalIsOpen: true });
    }


    openConfirmModal(info){
        this.setState({ confirmModalIsOpen: true });
    }
    
    openNewLineItemModal(e){
        if(e){
            e.preventDefault();
        }
        this.resetLineItemTemplate();
        this.setState({ newLineItemModalIsOpen: true});
        if(this.state.lineItemToSearch){
            let lineItemTemplate = this.state.lineItemTemplate;
            lineItemTemplate.label = this.state.lineItemToSearch;
            this.setState({ lineItemTemplate : lineItemTemplate});
        }
        this.closeSearchLineItemPanel();
    }

    closeNewLineItemModal(){
        this.setState({ newLineItemModalIsOpen: false });
    }

    handleCategoryTextChangeMask(field, key ,value) {
        this.props.vTerminal.handleCategoryTextChangeMask(field, key ,value);
    }
    
    
    resetLineItemTemplate() {
        this.setState({ lineItemTemplate: {
            name: '',
            type: '',
            label: '',
            value: '',
            description: '',
            quantity: 1,
            showDescription: true
        }});
    }

    reviewPayment(){

        this.validateFields();

        if(this.props.vTerminal.getPaymentPageErrors.credentialsMaxMinTicketError){
            this.openInfoModal('Sorry, the amount to be paid is greater or less than what is allowed.');
            return;
        }

        if(this.props.vTerminal.totalAmount.netAmount === 0){
            this.openInfoModal('Sorry, the amount must be greater than $0.00');
            return;
        }
        else{
            if(!this.props.vTerminal.hasPaymentPageErrors()){
                if(this.props.vTerminal.getPaymentMethod === "device"){

                    this.openSwipeModal();
                    this.props.vTerminal.makePayment(this.props.vTerminal.operation ? this.props.vTerminal.operation : "sale").then(response => {
                        this.closeSwipeModal();
                        if(response.data.responseData.resultCode && response.data.responseData.resultCode !== 1){
                            let message = "";
                            if(response.data.responseData.resultText){
                                message = response.data.responseData.resultText;
                            }else{
                                message = response.data.responseText ? "Sorry, the payment was " + response.data.responseText : 'Sorry, we have a problem sending your payment.';
                            }
                            this.setState({
                                paymentApproved:false, 
                                receiptModalIsOpen: true, 
                                paymentResponseMessage: message, 
                                referenceId: response.data.responseData.referenceId
                            });
            
                        } else {
                            if (response.data.responseData.referenceId) {
                                //this.props.vTerminal.sendReceipt(response.data.responseData.referenceId);
                            }
                            this.setState({
                                paymentApproved:true, 
                                receiptModalIsOpen: true, 
                                paymentAuthCode: response.data.responseData.authCode ? response.data.responseData.authCode : '-',  
                                referenceId: response.data.responseData.referenceId ? response.data.responseData.referenceId : '-'
                            });
                            
                        }
                    })
                    .catch(error => {
                        console.error(error)
                        this.closeSwipeModal();
                        let message = (error.response && error.response.data && error.response.data.responseText) ? error.response.data.responseText : 'Sorry, we have a problem sending your payment.';
                       
                        this.setState({
                            paymentApproved:false, 
                            receiptModalIsOpen: true, 
                            paymentResponseMessage: message
                        });
                    });
                }
                else{
            
                    this.openConfirmModal();
                }
                
            }
        }
    }

    resetForm(){

    }

    makePayment(){

        this.props.global.setLoading(true);
        this.props.vTerminal.makePayment(this.props.vTerminal.operation ? this.props.vTerminal.operation : "sale", this.props.vTerminal.paymentPage.paymentMethods.savePaymentMethodForFuture)
        .then((response) => {
            
            this.props.global.setLoading(false);
            if(response.data.responseData.resultCode && response.data.responseData.resultCode !== 1){
                let message = "", messageAvs = "", messageCvv = "";
                if(response.data.responseData.resultText){
                    message = response.data.responseData.resultText;
                }else{
                    message = response.data.responseText ? "Sorry, the payment was " + response.data.responseText : 'Sorry, we have a problem sending your payment.';
                }
                if (response.data.responseData.cvvResponseText){
                    messageCvv = response.data.responseData.cvvResponseText;
                }
                if (response.data.responseData.avsResponseText){
                    messageAvs = response.data.responseData.avsResponseText;
                }
                this.setState({
                    paymentApproved:false, 
                    receiptModalIsOpen: true, 
                    paymentResponseMessage: message,
                    paymentResponseMessageCvv: messageCvv,
                    paymentResponseMessageAvs: messageAvs,
                    referenceId: response.data.responseData.referenceId
                });

            }else{
                if (response.data.responseData.referenceId) {
                    //this.props.vTerminal.sendReceipt(response.data.responseData.referenceId);
                }
                this.setState({
                    paymentApproved:true, 
                    receiptModalIsOpen: true, 
                    paymentAuthCode: response.data.responseData.authCode ? response.data.responseData.authCode : '-',  
                    referenceId: response.data.responseData.referenceId ? response.data.responseData.referenceId : '-'
                });
                
                
                if(this.props.paymentSuccessFunctionCallBack){
                    this.props.paymentSuccessFunctionCallBack();
                }
            }
            
        })
        .catch((reason) => {
            console.error(reason)
            this.props.global.setLoading(false);
            let message = "";
            if(reason.data){
                message = reason.data.responseText ? reason.data.responseText : 'Sorry, we have a problem sending your payment.';
            }else if(reason.request.response){
                if (typeof reason.request.response === 'string') {
                    let messageJson = JSON.parse(reason.request.response);
                    if(messageJson.responseText){
                        message = messageJson.responseText;
                    }else{
                        message = reason.request.response;
                    }
                   
                }
                else {
                    
                    message = JSON.parse(reason.request.response).responseText;
                }
            }
            
            this.setState({paymentApproved:false, receiptModalIsOpen: true, paymentResponseMessage: message, referenceId: (reason.data && reason.data.responseData && reason.data.responseData.referenceId ) ? reason.data.responseData.referenceId : null });
            
        });
        this.closeConfirmModal();
    }

    setPaymentMethod(method, activeKey){
        if(method === "device" && this.state.responseList && this.state.responseList.length === 1){
            let defaultValue = this.state.responseList[0];
            if(defaultValue){
                this.setState({deviceDescription:defaultValue.deviceNickName});
                this.props.vTerminal.handleTextChangeACH('device', defaultValue.deviceId);
            }
        }
        this.props.vTerminal.setPaymentMethod(method);
        if(activeKey!==null){
            this.props.vTerminal.setDefaultPaymentMethodActiveKey(activeKey);
        }
        this.props.vTerminal.setSavedPaymentMethodSelected('');
    }

    validateFields(){
        
        var paymentPage = this.props.vTerminal.paymentPage;
        var validators = this.props.global.validators;
        var reactObj = this;
        this.props.vTerminal.clearPaymentPageError();
        //let globalConfig = this.props.pconfig;

        // validating autopay fields
        if(this.props.vTerminal.isAutopay){
            if(validators.isEmpty(paymentPage.autopay.startDate) || validators.isMaxLength(250, paymentPage.autopay.startDate.toString()))
            {
                this.props.vTerminal.setPaymentPageError('autopayStartDateError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('autopayStartDateError',false);
            }

            if(validators.isEmpty(paymentPage.autopay.frequencySelected) || validators.isMaxLength(250, paymentPage.autopay.frequencySelected.toString()))
            {
                this.props.vTerminal.setPaymentPageError('autopayFrequencyError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('autopayFrequencyError',false);
            }

            if(validators.isEmpty(paymentPage.autopay.finishSelected) || validators.isMaxLength(250, paymentPage.autopay.finishSelected.toString()))
            {
                this.props.vTerminal.setPaymentPageError('autopayFinishError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('autopayFinishError',false);
            } 
        }
        else{
            this.props.vTerminal.setPaymentPageError('autopayStartDateError',false);
            this.props.vTerminal.setPaymentPageError('autopayFrequencyError',false);
            this.props.vTerminal.setPaymentPageError('autopayFinishError',false);
        }
        
        // validating ach form
        if(this.props.vTerminal.getPaymentMethod === "ach"){

            this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsSavedError',false);

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountHolderName) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountHolderName))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccountType) || validators.isMaxLength(250, paymentPage.paymentMethods.achAccountType))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achRouting) || !this.props.global.isValidRouting(paymentPage.paymentMethods.achRouting) || validators.isMaxLength(9, paymentPage.paymentMethods.achRouting) || validators.stringValidator('routing', paymentPage.paymentMethods.achRouting))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
            }

            if(validators.isEmpty(paymentPage.paymentMethods.achAccount) ||  validators.stringValidator('numbers', paymentPage.paymentMethods.achAccount) || validators.isMinLength(4, paymentPage.paymentMethods.achAccount) || validators.isMaxLength(17, paymentPage.paymentMethods.achAccount))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);
            }
        }else if(this.props.vTerminal.getPaymentMethod === "card"){

            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsSavedError',false);
            let ccType = this.props.global.creditCardType(paymentPage.paymentMethods.cardNumber);

            let ccTypeAux = ccType;
            if(ccType === "american-express"){
                ccTypeAux  =  "amex";
            }


            if(ccTypeAux === "unknown" || validators.isEmpty(paymentPage.paymentMethods.cardNumber) || (ccTypeAux === "amex" ? !validators.isLength(15, paymentPage.paymentMethods.cardNumber) : !validators.isLength(16, paymentPage.paymentMethods.cardNumber)) || validators.stringValidator('card', paymentPage.paymentMethods.cardNumber) || this.state.config.card[ccTypeAux] === false)
            {
                if(ccTypeAux === "unknown"){
                    this.props.vTerminal.setPaymentPageErrorMessage('cardNumber',"Your card number is invalid");
                }else{
                    this.props.vTerminal.setPaymentPageErrorMessage('cardNumber',"Your card number is incomplete");
                }

                if(this.state.config.card[ccTypeAux] === false){
                    this.props.vTerminal.setPaymentPageErrorMessage('cardNumber',`${this.props.global.getCardFullName(ccTypeAux)} not accepted`);
                }

                this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('cardNumber',null);
            }



            if(validators.isEmpty(paymentPage.paymentMethods.cardExpirationDate) || validators.stringValidator('exp', paymentPage.paymentMethods.cardExpirationDate))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',"Your card's expiration date is incomplete");
            }
            else{

                let expDateYear = paymentPage.paymentMethods.cardExpirationDate.substr(-2);
                let expDateMonth = paymentPage.paymentMethods.cardExpirationDate.substr(0,2);
                let currentYear =new Date().getFullYear().toString().substr(-2);
                let currentMonth =new Date().getMonth().toString();

                if(parseInt(expDateYear) < parseInt(currentYear)){
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',"Your card's expiration date is in the past");
                }
                else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',null);
                }


                if(((parseInt(expDateYear) === parseInt(currentYear)) && ((parseInt(expDateMonth) < parseInt(currentMonth)+1)  || (parseInt(expDateMonth) > 12)))){
                    if(parseInt(expDateMonth) > 12){
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                        this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',"Your card's expiration date is invalid");
                    }else{
                        this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',true);
                        this.props.vTerminal.setPaymentPageErrorMessage('cardExpirationDate',"Your card's expiration date is in the past");
                    }
                }

            }

            
            let globalConfig = this.state.config;

            if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardCvv && globalConfig.card.inputs.cardCvv.hidden !== true){
                if(validators.isEmpty(paymentPage.paymentMethods.cardCvv) || (ccType === "american-express" ? validators.stringValidator('cvvamex', paymentPage.paymentMethods.cardCvv) : validators.stringValidator('cvv', paymentPage.paymentMethods.cardCvv)))
                {
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',true);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardCvv',"Your card's security code is incomplete");
                }
                else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardCvv',null);
                }
            }

        
            if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.hidden !== true){

                let zipCodeValidator = validators.stringValidator('zipcode', paymentPage.paymentMethods.cardZipcode);
                if(globalConfig && globalConfig.card && globalConfig.card.inputs && globalConfig.card.inputs.cardZipcode && globalConfig.card.inputs.cardZipcode.country && Array.isArray(globalConfig.card.inputs.cardZipcode.country)){
                    zipCodeValidator = !this.props.global.checkZipFormat(globalConfig.card.inputs.cardZipcode.country, paymentPage.paymentMethods.cardZipcode)
                }

                if(validators.isEmpty(paymentPage.paymentMethods.cardZipcode) || zipCodeValidator)
                {
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',true);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardZipcode',"Your card's zip code is incomplete or incorrect");
                }
                else{
                    this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
                    this.props.vTerminal.setPaymentPageErrorMessage('cardZipcode',null);
                }
            }
		
            
            if((globalConfig?.card?.inputs?.cardHolderName?.required === true ? validators.isEmpty(paymentPage.paymentMethods.cardHolderName) : false) || validators.isMaxLength(250, paymentPage.paymentMethods.cardHolderName) || validators.stringValidator('alphanumericspaceslatin', paymentPage.paymentMethods.cardHolderName))
            {
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',true);
                this.props.vTerminal.setPaymentPageErrorMessage('cardHolderName',"Your cardholder name is invalid");
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',false);
                this.props.vTerminal.setPaymentPageErrorMessage('cardHolderName',null);
            }

        }else if(this.props.vTerminal.getPaymentMethod === '' || this.props.vTerminal.getPaymentMethod === 'savedcard' || this.props.vTerminal.getPaymentMethod === 'savedach'){
            
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardNumberError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardExpirationDateError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardCvvError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardZipcodeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsCardHolderNameError',false);

            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountHolderNameError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountTypeError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchRoutingError',false);
            this.props.vTerminal.setPaymentPageError('paymentMethodsAchAccountError',false);

            if(validators.isEmpty(paymentPage.paymentMethods.paymentMethodSaved)){
                this.props.vTerminal.setPaymentPageError('paymentMethodsSavedError',true);
            }
            else{
                this.props.vTerminal.setPaymentPageError('paymentMethodsSavedError',false);
            }
        }

        // validating customer
        if(!this.props.vTerminal.hasCustomerSelected){
            this.props.vTerminal.setPaymentPageError('customerError',true);
        }else{
            this.props.vTerminal.setPaymentPageError('customerError',false);
        }

         // validating payment categories
        var categoriesConfig = Object.entries(toJS(this.props.vTerminal.amountCategories));
        categoriesConfig.forEach(function (item, index) {
            if(!item[1].optionalPay && (validators.isEmpty(item[1].value) || parseFloat(item[1].value) === 0 || isNaN(item[1].value)) ){
                reactObj.props.vTerminal.setPaymentPageError([item[1].name],true)
            }
            else{
                reactObj.props.vTerminal.setPaymentPageError([item[1].name],false)
            }
        });

        // validating payor fields
        this.props.vTerminal.setPaymentPageError('payorInvoiceNumberError',false);
        if(validators.isMaxLength(250, paymentPage.payor.invoiceNumber.value) || validators.stringValidator('text', paymentPage.payor.invoiceNumber.value))
        {
            this.props.vTerminal.setPaymentPageError('payorInvoiceNumberError',true);
        }

        // validating notes field
        this.props.vTerminal.setPaymentPageError('notesError',false);
        if(validators.isMaxLength(250, paymentPage.notes.value))
        {
            this.props.vTerminal.setPaymentPageError('notesError',true);
        }

        this.props.vTerminal.validateCredentialsMaxMinTicketError();

        this.goToInputError();

    }

    goToInputError(){
        setTimeout(function () {
			try {
				let inputs = document.getElementsByClassName('input-error');
				if (inputs[0]) {
					let objRect = inputs[0].getBoundingClientRect();
					window.scrollTo({
						top: parseInt(objRect.top + window.scrollY - 120),
						behavior: 'smooth',
					});
					inputs[0].focus();
				}
			} catch (e) {
				console.log(e);
			}
		}, 200);
    }

    renderAmountCategories(){
        var loopData = [];
        var categoriesConfig = Object.entries(toJS(this.props.vTerminal.amountCategories));
        var categoriesCount = categoriesConfig.length;
       
        var reactObject = this;
        categoriesConfig.forEach(function (item, index) {

            if(categoriesCount > 1 || categoriesConfig[0][1].name !== "amountDefaultPayabliApp") {
            
                loopData.push(
                    <li key={"keyAmountCategory"+item[0]} className="ui-state-default dragable-panel" id={"blockcategory"+item[0]}>
                        <div className="row mb-3 line-item">
                            <div className="col-8">
                                <div className="form-floating form-floating-money">
                                    <NumberFormat
                                        onFocus={(e)=>reactObject.focus(e)} 
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator="."
                                        displayType="input"
                                        type="text"
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        decimalScale={2}
                                        fixedDecimalScale={true}

                                        value={item[1].value}
                                        placeholder={item[1].label}
                                        className={reactObject.props.vTerminal.getPaymentPageErrors[item[1].name] ? "form-control input-error input-money" : "form-control input-money" }
                                        onValueChange={(values) => reactObject.handleCategoryTextChangeMask(item[0],"value", values.value)}
                                        readOnly={ (item[1].type === 'fixed' || item[1].type === 'quantity') ? true : false }
                                    />
                                    <label>{item[1].label}</label>
                                    { item[1].type === 'quantity'  &&
                                    <div className='input-group input-group-qty'>
                                        <button id={"amountCategoryQtySubstract"+item[0]} className="btn btn-outline-light btn-sm" type="button" onClick={(e) => reactObject.handleCategoryQtySubstract(e)}>-</button>
                                        <input type="text" className="form-control" placeholder="" aria-label="Example text with button addon" readOnly value={item[1].quantity}/>
                                        <button id={"amountCategoryQtyAdd"+item[0]} className="btn btn-outline-light btn-sm" type="button" onClick={(e) => reactObject.handleCategoryQtyAdd(e)}>+</button>
                                    </div>
                                    }
                                </div>
                                
                                { (item[1].description && item[1].showDescription)  ? <p className="small-grey-m0 mt-1">{item[1].description}</p> : ''}
                            </div>
                            <div className="col-3 text-right mt-2">
                                <b>${reactObject.props.global.numberWithCommas(parseFloat(item[1].value * item[1].quantity).toFixed(2))}</b>
                            </div>
                            <div className="col-1 text-right">
                                <div className="category-icon mt-2">
                                <button className="remove-icon-btn" type="button" onClick={(e) => reactObject.removeAmountCategory(e)} id={"categoryRemove"+item[0]}>&nbsp;</button>
                                </div>
                            </div>
                        </div>

                    </li>
                )
            }else{
                loopData.push(
                    <li key={"keyAmountCategory"+item[0]} className="text-center" id={"blockcategory"+item[0]}>
                    <label className="small mb-3">{categoriesConfig[0][1].label}</label>
                    <NumberFormat
                        onFocus={(e)=>reactObject.focus(e)} 
                        thousandsGroupStyle="thousand"
                        prefix="$"
                        decimalSeparator="."
                        displayType="input"
                        type="text"
                        thousandSeparator={true}
                        allowNegative={false}
                        decimalScale={2}
                        fixedDecimalScale={true}

                        value={item[1].value}
                        placeholder={"$"}
                        className={"big-amount"}
                        onValueChange={(values) => reactObject.handleCategoryTextChangeMask(item[0],"value", values.value)}
                        readOnly={(item[1].type === 'fixed' || item[1].type === 'quantity') ? true : false }
                    />
                    
                    <div className="row mb-4">
                
                        <div className="col-12">
                            <div className="form-floating">
                                <input style={{fontSize: "12px"}} value={categoriesConfig[0][1].description} className="form-control" placeholder="Description" onChange={(e)=>reactObject.handleFirstCategoryTextChange(e)} />
                                <label htmlFor="customer">Description</label>
                            </div>
                        </div>
                        
                    </div>
                    
                 
                    </li>
                )
            }
        });
        
        return loopData;
    }

    disableAutopay(checked){
        this.props.vTerminal.setDefaultPaymentMethodActiveKey(2);
        this.props.vTerminal.setPaymentMethod('');
        this.props.vTerminal.handleTextChangeACH('paymentMethodSaved', null);
        this.props.vTerminal.setSavedPaymentMethodSelected("");

        this.props.vTerminal.disableAutopay(checked);
    }

    handleAmountCategoryType(e){
        let lineItemTemplate = this.state.lineItemTemplate;
        lineItemTemplate.type = e.target.value;
        this.setState({ lineItemTemplate: lineItemTemplate });
    }

    handleCategoryTextChange(key ,e) {
        let lineItemTemplate = this.state.lineItemTemplate;
        lineItemTemplate[key] = e.target.value;
        this.setState({ lineItemTemplate: lineItemTemplate });
    }

    handleFirstCategoryTextChange(e) {
        this.props.vTerminal.handleFirstCategoryTextChange(e.target.value);
    }

    handlePayorTextChange(field, value){
        this.props.vTerminal.handlePayorTextChange(field, value);
    }
    
    handleNotesTextChange(e){
        this.props.vTerminal.handleNotesTextChange(e.target.value);
    }

    addLineItem(){
        let lineItemTemplateLabelErrors = false;
        let lineItemTemplateAmountErrors = false;
        let validators = this.props.global.validators;

        lineItemTemplateLabelErrors = validators.isEmpty(this.state.lineItemTemplate.label) || validators.isMaxLength(250, this.state.lineItemTemplate.label) ? true : false;
        lineItemTemplateAmountErrors = validators.isEmpty(this.state.lineItemTemplate.value) || validators.isMaxLength(250, this.state.lineItemTemplate.value) ? true : false;
        this.setState({ 
            lineItemTemplateLabelErrors : lineItemTemplateLabelErrors,
            lineItemTemplateAmountErrors : lineItemTemplateAmountErrors
        }, function(){

            if(!this.state.lineItemTemplateLabelErrors && !this.state.lineItemTemplateAmountErrors){
                this.props.global.setLoading(true);
                this.props.vTerminal.addNewLineItemField(this.state.lineItemTemplate).then(res => {
                    this.props.global.setLoading(false);
                    this.setState({lineItemToSearch: ''});
                })
                .catch(error => {
                    console.error(error)
                    this.props.global.setLoading(false);
                    //let errorMessage = error.response && error.response.data.responseText ? error.response.data.responseText : "Something is Wrong creating the item!";
                });
                this.closeNewLineItemModal();
            }
            
        });
    }


    
    handleCategoryAmount(key ,value) {
        let lineItemTemplate = this.state.lineItemTemplate;
        lineItemTemplate[key] = value;
        this.setState({ lineItemTemplate: lineItemTemplate });
    }

    handleCategoryShowDescription(){
        let lineItemTemplate = this.state.lineItemTemplate;
        lineItemTemplate.showDescription = !lineItemTemplate.showDescription;
        this.setState({ lineItemTemplate: lineItemTemplate });
    }
    
    renderPaymentMethodReceipt(){

        if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'device' ){
            return (
                <>
                    {this.props.global.getPaymethodImg("device")} &nbsp;&nbsp; {this.state.deviceDescription}
                </>
            )
        }

        if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'card' || this.props.vTerminal.getPaymentMethod.toLowerCase() === 'savedcard' ){
            return (
                <>
                    {
                        this.props.vTerminal.getPaymentMethod.toLowerCase() === 'card' ?
                        <>
                        {this.props.global.getPaymethodImg(this.props.global.creditCardType(this.props.vTerminal.paymentPage.paymentMethods.cardNumber))}
                        &nbsp;&nbsp;&nbsp;{this.props.vTerminal.paymentPage.paymentMethods.cardNumber ? this.props.global.maskedCardNumber(this.props.vTerminal.paymentPage.paymentMethods.cardNumber, "v3"): ''}
                        </>
                        :
                        <>
                        {this.props.vTerminal.getCustomerSelected && this.props.vTerminal.paymentMethodSavedPosition && this.props.vTerminal.getCustomerSelected.StoredMethods && this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition] ? this.props.global.getPaymethodImg(this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition].Descriptor) : ''}
                        &nbsp;&nbsp;&nbsp;
                        {this.props.vTerminal.getCustomerSelected && this.props.vTerminal.paymentMethodSavedPosition && this.props.vTerminal.getCustomerSelected.StoredMethods && this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition] ? this.props.global.maskedCardNumber(this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition].MaskedAccount, "v3") : ''}
                        </>
                    }
                </>
            )
        }
        
        if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'ach' || this.props.vTerminal.getPaymentMethod.toLowerCase() === 'savedach' ){
            return (
                <>
                {this.props.global.getPaymethodImg()} &nbsp;&nbsp;&nbsp; Bank account / E-Check
                </>
            )
        }
        
    }

    renderPaymentInformation(){
        if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'card' || this.props.vTerminal.getPaymentMethod.toLowerCase() === 'savedcard'){
            return (
                this.props.vTerminal.getPaymentMethod.toLowerCase() === 'card' 
                    ?
                    <>
                        {this.props.global.getPaymethodImg(this.props.global.creditCardType(this.props.vTerminal.paymentPage.paymentMethods.cardNumber))}
                        &nbsp;&nbsp;&nbsp;{this.props.vTerminal.paymentPage.paymentMethods.cardNumber ? this.props.global.maskedCardNumber(this.props.vTerminal.paymentPage.paymentMethods.cardNumber, "v3"): ''}
                    </>
                    :
                    <>
                        {this.props.vTerminal.getCustomerSelected && Number.isInteger(this.props.vTerminal.paymentMethodSavedPosition) && this.props.vTerminal.getCustomerSelected.StoredMethods && this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition] ? this.props.global.getPaymethodImg(this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition].Descriptor) : ''}
                        &nbsp;&nbsp;&nbsp;
                        {this.props.vTerminal.getCustomerSelected && Number.isInteger(this.props.vTerminal.paymentMethodSavedPosition) && this.props.vTerminal.getCustomerSelected.StoredMethods && this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition] ? this.props.global.maskedCardNumber(this.props.vTerminal.getCustomerSelected.StoredMethods[this.props.vTerminal.paymentMethodSavedPosition].MaskedAccount, "v3") : ''}
                    </>
            )            
        }else if(this.props.vTerminal.getPaymentMethod.toLowerCase() === 'ach' || this.props.vTerminal.getPaymentMethod.toLowerCase() === 'savedach' ){
            return (
                <>
                    {this.props.global.getPaymethodImg()} &nbsp;&nbsp;&nbsp; Bank account / E-Check
                </>
            )
        }
    }

    render() {
        let credentialsPermissions = this.props.vTerminal.getCredentialsPermissions;
        return (
            
            <div>

                <Modal style={{textAlign: "center"}} show={!this.state.infoModalIsOpen} onHide={this.closeInfoModal}  size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <BiMessageSquareError className="icon-modal"/>
                    <h5>Info</h5>
                    <p className="small">{this.state.infoMessageInModal}</p>
                    <Button className="btn" onClick={this.closeInfoModal}>
                    Close
                    </Button>
                </Modal.Body>
                </Modal>

                <Modal show={this.state.receiptModalIsOpen} onHide={this.closeReceiptModal}  size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div className="text-center mt-4">
                    <b>Payment Receipt</b>
                    <h2 className="mb-4">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['totalAmount']).toFixed(2))}</h2>
                    { this.state.paymentApproved ?
                      <p><BiCheckCircle style={{fontSize: '28px', color: "rgb(136,201,82)"}}/> Approved</p>
                      :
                      <>
                      <p className="mb-2"><BiCheckCircle style={{fontSize: '28px', color: "rgb(255, 100, 82)"}}/> Declined</p>
                      <p className="small-grey mt-0 mb-2">{this.state.paymentResponseMessage}</p>
                      </>
                    }
                   
                    </div>

                    <div className="mt-4 small mb-3">
                        {
                            this.props.vTerminal.amountCategories.map((record, i) => (
                                <div key={i} className="row">
                                    <div className="col-6 mb-1">{record.label} x {record.quantity}</div>
                                    <div className="col-6 text-right mb-1">${this.props.global.numberWithCommas(parseFloat(record.value).toFixed(2))}</div>
                                </div>
                            ))
                        }
                    </div>

                    <div className="review-total mb-3" style={{borderBottom: "none"}}>
                        <h6 className="sub-header-line mb-3">Payment Details</h6>

                        <div className="row mb-3 mt-3">
                            <div className="col-6">
                                {this.renderPaymentMethodReceipt()}
                            </div>
                            <div className="col-6 text-right">
                                {!this.props.vTerminal.isAutopay ?
                                <small>
                                Paid on {this.props.global.stringDateFormat(new Date().toString())}, {this.props.global.stringTimeFormat(new Date().toString())}
                                </small>
                                :
                                <small>
                                Next date {this.props.global.stringDateFormat(this.props.vTerminal.paymentPage.autopay.startDate)}
                                </small>
                                }
                            </div>
                        </div>
                       

                        <div className="row">
                        <div className="col">Payment:</div>
                        <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['netAmount']).toFixed(2))}</div>
                        </div>
                        <div className="row">
                        <div className="col">Fee:</div>
                        <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['fee']).toFixed(2))}</div>
                        </div>

                        <div className="top-border-grey mt-2 bottom-border-grey">
                            <div className="row total-amount">
                            <div className="col">Total Amount:</div>
                            <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['totalAmount']).toFixed(2))}</div>
                            </div>
                        </div>

                        <div className="mt-2">
                            <div className="row total-amount">
                                <div className="col-4">Reference Id: </div>
                                <div className="col-8">{ this.state.referenceId ? this.state.referenceId : ""}</div>
                                { this.state.paymentApproved &&
                                    <>
                                    <div className="col-4">AuthCode: </div>
                                    <div className="col-8">{ this.state.paymentAuthCode ? this.state.paymentAuthCode : ""}</div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    


                    <Button id="btnCloseReceipt" className="btn full-w btn-lg" onClick={this.closeReceiptModal}>
                        Close
                    </Button>

                   
                </Modal.Body>
                </Modal>

                <Modal show={this.state.newLineItemModalIsOpen} onHide={this.closeNewLineItemModal}  size="md" centered >
                <Modal.Body className="popover-body">
                        <div className="row mb-2">
                            <div className="col"><h6>Price Field</h6></div>
                        </div>
                        <label className="header">Select amount type</label>

                        <div className="icheck-primary">
                        <input name="amountCategoryType" defaultChecked={true} type="radio" id={"amountCategoryTypeFixed"} value="fixed" onChange={(e) => this.handleAmountCategoryType(e)}/>
                        <label htmlFor={"amountCategoryTypeFixed"}>Fixed amount</label>
                        <label className="small small-grey">Payers must pay the specific amount for this price field.</label>
                        </div>


                        <div className="icheck-primary">
                        <input name={"amountCategoryType"} type="radio" id={"amountCategoryTypeQuantity"} value="quantity" onChange={(e) => this.handleAmountCategoryType(e)}/>
                        <label htmlFor={"amountCategoryTypeQuantity"}>Fixed item amount with quantity</label>
                       
                        <label className="small small-grey">Facilitate your payers with quantity selection of an item with a fixed cost.</label>
            
                        </div>



                        <div className="row">
                        <div className="col-7">
                            <input value={this.state.lineItemTemplate.label} onChange={(e) => this.handleCategoryTextChange("label", e)} type="text" className={ this.state.lineItemTemplateLabelErrors ? "form-control mb-3 input-error" : "form-control mb-3" } placeholder="Item Name"/>
                        </div>
                        <div className="col-5">
                            <NumberFormat
                                onFocus={(e)=>this.focus(e)} 
                                thousandsGroupStyle="thousand"
                                decimalSeparator="."
                                displayType="input"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={false}
                                decimalScale={2}
                                fixedDecimalScale={true}

                                placeholder='Amount'
                                className={ this.state.lineItemTemplateAmountErrors ? "form-control mb-3 input-error input-money" : "form-control mb-3 input-money" }
                                onValueChange={(values) => this.handleCategoryAmount("value", values.value)}
                            />

                        </div>
                      
                        <div className="col-12">
                            <input onChange={(e) => this.handleCategoryTextChange("description", e)} type="text" className="form-control mb-1" placeholder="Item description (optional)" />
                        </div>
                   
                        </div>

                        <div className="card-v2 mb-4 info-message-card" style={{position: "relative"}}>
                            <span className="badge bg-dark card-info-badge">Preview</span>

                            <div className="row line-item">
                            <div className="col-7">
                                <div className="form-floating form-floating-money">
                                    <NumberFormat
                                        onFocus={(e)=>this.focus(e)} 
                                        thousandsGroupStyle="thousand"
                                        decimalSeparator="."
                                        displayType="input"
                                        type="text"
                                        thousandSeparator={true}
                                        allowNegative={false}
                                        decimalScale={2}
                                        fixedDecimalScale={true}

                                        value={this.state.lineItemTemplate.value}
                                        placeholder={this.state.lineItemTemplate.label ? this.state.lineItemTemplate.label : "Label"}
                                        className={ "form-control input-money" }
                                        readOnly={ true }
                                    />
                                    <label className='inputLabelLimitSize'>{this.state.lineItemTemplate.label ? this.state.lineItemTemplate.label : "Label"}</label>
                                    { this.state.lineItemTemplate.type === 'quantity'  &&
                                    <div className='input-group input-group-qty' style={{ top: "6px", right: "8px"}}>
                                        <button id={"amountCategoryQtySubstractPreview"} className="btn btn-outline-light btn-sm" type="button" >-</button>
                                        <input type="text" className="form-control" placeholder="" aria-label="Example text with button addon" readOnly value="1"/>
                                        <button id={"amountCategoryQtyAddPreview"} className="btn btn-outline-light btn-sm" type="button" >+</button>
                                    </div>
                                    }
                                </div>
                                
                                { this.state.lineItemTemplate.description  ? <p className="small-grey-m0 mt-1 text-left overflowWrap">{this.state.lineItemTemplate.description}</p> : ''}
                            </div>
                            <div className="col-3 text-right mt-1">
                                <b>${this.props.global.numberWithCommas(parseFloat(this.state.lineItemTemplate.value ? this.state.lineItemTemplate.value : 0).toFixed(2))}</b>
                            </div>
                            <div className="col-2 text-right">
                                <div className="category-icon mt-1">
                                <button className="remove-icon-btn" type="button" >&nbsp;</button>
                                </div>
                            </div>
                        </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <button className="btn full-w btn-light" type="button" onClick={() => this.closeNewLineItemModal() }>Cancel</button>
                            </div>
                            <div className="col">
                            <button className="btn full-w btn-primary" type="button" onClick={() => this.addLineItem()}>Add</button>   
                            </div>
                        </div>
                        
                        
                </Modal.Body>
                </Modal>

                <Modal show={this.state.confirmModalIsOpen} onHide={() => this.closeConfirmModal()}  size="md" centered >
                <Modal.Body className="popover-body modal-body">
                        <h6 className="sub-header-line mb-3">Payment Information</h6>
                        
                        <div className="row mb-3">
                            <div className="col-7">
                                {this.renderPaymentInformation()}
                            </div>
                            <div className="col-5 text-right">
                                {!this.props.vTerminal.isAutopay ?
                                <small>
                                Paid on {this.props.global.stringDateFormat(new Date().toString())}
                                </small>
                                :
                                <small>
                                Next date {this.props.global.stringDateFormat(this.props.vTerminal.paymentPage.autopay.startDate)}
                                </small>
                                }
                            </div>
                        </div>
                        
                        <div className="review-total">
                            <h6 className="sub-header-line mb-3">Review & Send Payment</h6>
                            <div className="row">
                            <div className="col">Net Amount:</div>
                            <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['netAmount']).toFixed(2))}</div>
                            </div>
                            <div className="row">
                            <div className="col">Fee:</div>
                            <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['fee']).toFixed(2))}</div>
                            </div>

                            <div className="row total-amount">
                            <div className="col">Total Amount:</div>
                            <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['totalAmount']).toFixed(2))}</div>
                            </div>
                        </div>

                        <button type="button" onClick={(e) => this.makePayment()} className="btn btn-success btn-lg full-w">Pay ${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['totalAmount']).toFixed(2))}</button>
                        <div className="text-center mt-3">
                        <button className="btn btn-default" onClick={() => this.closeConfirmModal()}>Cancel</button>
                        </div>
                </Modal.Body>
                </Modal>

                <Modal show={this.state.swipeModalIsOpen} onHide={() => function(){}}  size="sm" centered >
                <Modal.Body>
                        
                        <div className="row mb-3 mt-4 text-center">
                            <div className="text-center mb-3">
                                <img alt="" style={{width: '60px', opacity: 0.2}} src={pointOfSale}/>
                            </div>
                            <p className="mb-0">Processing payment <BiRefresh className="refreshIcon"/></p>
                            <p className="small-grey mt-0">{this.state.deviceDescription}</p>
                        </div>
                </Modal.Body>
                </Modal>

                <ul className="ul-nomargin">
                               
                                { (this.props.pconfig.recurringPayment === true && this.props.pconfig.oneTimePayment === true) &&
                                <li className="mb-4">
                                    <div className="row">
                                        {(credentialsPermissions.ach.onetime || credentialsPermissions.card.onetime || credentialsPermissions.wallet.onetime || credentialsPermissions.cloud.onetime) &&
                                        <div className="col"><button onClick={() => this.disableAutopay(false)} type="button" className={this.props.vTerminal.isAutopay? "btn full-w btn-outline-primary":"btn full-w btn-primary"}>One Time</button></div>
                                        }
                                        {(credentialsPermissions.ach.recurring || credentialsPermissions.card.recurring || credentialsPermissions.wallet.recurring || credentialsPermissions.cloud.recurring) &&
                                        <div className="col"><button onClick={() => this.disableAutopay(true)} type="button" className={this.props.vTerminal.isAutopay? "btn full-w btn-primary":"btn full-w btn-outline-primary"}>Schedule</button></div>
                                        }
                                    </div>
                                </li>
                                }
                           

                                <li className="mb-2" id="transactiontype">
                                    <div className="form-floating">
                                        <select value={this.props.vTerminal.operation} onChange={(e) => this.handleOperation(e)} className="form-select form-control" id="operation" name="operation">
                                            <option value="sale">Sale</option>
                                            <option value="authorize">Authorize or "Capture Later"</option>
                                        </select>
                                        <label htmlFor="operation">Operation</label>
                                    </div>
                                </li>
                                <li className="mb-4" id="blockamount">
                                    <div>
                                        <ul style={{paddingLeft: 0}} className="mb-3" ref={this.sortableCategories}>
                                            {this.renderAmountCategories()}
                                        </ul>
                                        
                                        <div className="form-floating">
                                            <input autoComplete="off" onFocus={(e) => this.searchLineItemTyping(e)} onBlur={(e) => this.closeSearchLineItemPanelDelay(e)} onChange={(e) => this.searchLineItemTyping(e)} value={this.state.lineItemToSearch} id="line-item" className={ !this.state.searchingLineItem ? "form-control search-input": "form-control search-input loading"} placeholder="Find or add line item…" />
                                            <label htmlFor="line-item">Find or add line item…</label>
                                
                                            <div className={ this.state.searchLineItemPanelIsOpen ? "search-dropdown-menu" : "d-none search-dropdown-menu" }>
                                                <a style={{color: "#10A0E3"}} href="/" onClick={(e) => this.openNewLineItemModal(e)} className="dropdown-item" >+ Add line item</a>
                                                {
                                                    this.props.vTerminal.lineItems.map((record, i) => (
                                                        <a key={i} href="/" onClick={(e) => this.selectLineItem(i,e)} className="dropdown-item" >{record.itemProductName} <span style={{float:"right"}}>${this.props.global.numberWithCommas(parseFloat(record.itemCost).toFixed(2))}</span></a>
                                                    ))                                                    
                                                }
                                                { (this.props.vTerminal.lineItems.length === 0 && this.state.lineItemToSearch.length > 2) &&
                                                    <div className="small-grey ml-2">No results found</div>
                                                }

                                            </div>
                                           
                                            
                                        </div>
                                    </div>
                                </li>

                                { this.props.vTerminal.paymentPage.autopay.enabled &&
                                <li className="ui-state-default dragable-panel" id="blockautopay">
                                    <h6 className="sub-header mb-3">Autopay</h6>                        
                                    <AutopayForm/>
                                </li>
                                }

                                <li id="blockpayer" className="mb-4" style={{position: "relative"}}>
                                    <label className="optionalLabelInfo" style={{fontSize: "11px"}}>(Optional)</label>
                                    <div className="form-floating">
                                        <IMaskInput
                                            mask={this.props.global.maskValidator(this.props.vTerminal.paymentPage.payor.invoiceNumber.validation)}
                                            name="invoiceNumber"
                                            value={ this.props.vTerminal.paymentPage.payor.invoiceNumber.value  }
                                            unmask={true}
                                            maxLength="250"
                                            onAccept={
                                                (value, mask) => this.handlePayorTextChange('invoiceNumber', value)
                                            }
                                            placeholder={this.props.vTerminal.paymentPage.payor.invoiceNumber.label}
                                            className={this.props.vTerminal.getPaymentPageErrors.payorInvoiceNumberError ? "form-control input-error" : "form-control"}
                                            id="invoiceNumber"
                                        />
                                        <label htmlFor="invoiceNumber">{this.props.vTerminal.paymentPage.payor.invoiceNumber.label}</label>
                                    </div>
                                    
                                </li>

                                <li className="mb-4" id="blockCustomer">
                                    <h6 className="mb-3">Customer</h6>
                                    { !this.props.vTerminal.disableSearchCustomer ?
                                    <div className="form-floating">
                                        <input className={ !this.state.searchingCustomer ? this.props.vTerminal.getPaymentPageErrors.customerError ? "form-control search-input input-error" : "form-control search-input" : "form-control search-input loading"} autoComplete="off" onFocus={(e) => this.searchCustomerTyping(e)} onBlur={(e) => this.closeSearchCustomerPanelDelay(e)} onChange={(e) => this.searchCustomerTyping(e)} value={this.state.customerToSearch} id="customer" placeholder="Search for a customer" />
                                        <label htmlFor="customer">Search for a customer</label>
                                        
                                        <div className={ this.state.searchCustomerPanelIsOpen ? "search-dropdown-menu" : "d-none search-dropdown-menu" }>
                                            <EditCustomer pconfig={this.props.pconfig} rewriteSelectedWithResponse={true} functionCallbackNew={this.updateCustomerToSearch} defaultName={this.state.customerToSearch} title={"Add customer"} label={"+ Add customer"} style={{color: "#10A0E3"}} action={"new"}/>
                                            {
                                                this.props.vTerminal.customers.map((record, i) => (
                                                    <a key={i} href="/" onClick={(e) => this.selectCustomer(i,e)} className="dropdown-item" >{record.Firstname+" "+record.Lastname} - {record.Email}</a>
                                                ))
                                            }
                                            { (this.props.vTerminal.customers.length === 0 && this.state.customerToSearch.length > 2) &&
                                                    <div className="small-grey ml-2">No results found</div>
                                            }
                                        </div>
                                        
                                    </div>
                                    :
                                    <div className="form-floating">
                                        <input className="form-control" disabled id="customer" placeholder={this.props.vTerminal.getSelectedCustomerString} />
                                        <label htmlFor="customer">{this.props.vTerminal.getSelectedCustomerString}</label>
                                    </div>
                                    }
                                   
                                </li>

                                <li className="mb-4" id="blockpaymentMethods">
                                    <h6 className="mb-3">Payment Method</h6>
                                
                                    <div className="mb-3">
                                    <SelectPaymentMethodSaved/>
                                    </div>

                                    <Accordion activeKey={this.props.vTerminal.defaultPaymentMethodActiveKey}>
                                    
                                        {(this.props.pconfig && this.props.pconfig.device && this.props.pconfig.device.enabled === true) &&
                                        <>
                                            {
                                            (credentialsPermissions.cloud.onetime && this.state.responseList.length < 1) ?
                                            (<Card className="card card-in mb-3 card-hover">
                                                <Accordion.Toggle as={Card.Header}  className="card-body">
                                                    <div className="row">
                                                        <div className="col-2 text-center">
                                                        <div id="div-loading-layer" className="d-flex justify-content-center align-items-center" style={{margin: '3px'}}>
                                                        <div className="spinner-border" role="status"></div>
                                                        </div>
                                                        </div>
                                                        <div className="col-10">
                                                            Device / Terminal
                                                            <p className="small small-grey-m0">
                                                            Loading devices for selection...
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Accordion.Toggle>
                                            </Card>) :
                                            (<Card className="card card-in mb-3 card-hover">
                                                <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="3" onClick={(e) => this.setPaymentMethod('device', "3")}>
                                                    <div className="row">
                                                        <div className="col-2 text-center">
                                                        <img alt="" style={{width: '30px', margin: '3px'}} className="grey-icon-v2" src={pointOfSale}/>
                                                        </div>
                                                        <div className="col-10">
                                                            Device / Terminal
                                                            <p className="small small-grey-m0">
                                                            Tap, dip, or swipe a debit or credit card
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Accordion.Toggle>
                                                <Accordion.Collapse eventKey="3">
                                                    <Card.Body>
                                                        <div className="form-floating">
                                                            <select className={this.props.vTerminal.getPaymentPageErrors.paymentMethodsDeviceError ? "form-select form-control input-error" : "form-select form-control" } id="device" name="device" onChange={(e) => this.handleChangeDeviceSelect(e)}>
                                                                {(this.state.responseList.length !== 1) &&
                                                                <option value="">Select...</option>
                                                                }                                     
                                                                { this.state.responseList.map((record, i) => (
                                                                        <option key={i} className={record.deviceNickName} value={record.deviceId+"|||"+record.deviceNickName}>{record.deviceNickName} - {record.make} {record.model}</option>
                                                                    ))
                                                                }
            
                                                            </select>
                                                            <label htmlFor="device">Device Description & Model</label>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>)
                                            }
                                        </>
                                        }
                                    
                                    
                                    { (this.props.vTerminal.hasECheck && ((credentialsPermissions.ach.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.ach.recurring && this.props.vTerminal.isAutopay))) && 
                                    <Card className="card card-in mb-3 card-hover">
                                    <Accordion.Toggle as={Card.Header}  className="card-body" eventKey="1" onClick={(e) => this.setPaymentMethod('ach', "1")}>
                                        <div className="row">
                                            <div className="col-2 text-center">
                                                <img alt="" style={{width: '44px'}} className="grey-icon-v2" src={achIcon}/>
                                            </div>
                                            <div className="col-10">
                                                Bank account / E-Check
                                                <p className="small small-grey-m0">
                                                Pay from your Bank Account
                                                </p>
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                    <Card.Body>
                                        <PaymentMethodECheck config={this.props.pconfig} history={this.props.history} />
                                    </Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    }

                                    { (this.props.vTerminal.hasCards && ((credentialsPermissions.card.onetime && !this.props.vTerminal.isAutopay) || (credentialsPermissions.card.recurring && this.props.vTerminal.isAutopay)))  && 
                                    <Card className="card card-in mb-3 card-hover">
                                    <Accordion.Toggle as={Card.Header} className="card-body" eventKey="0" onClick={(e) => this.setPaymentMethod('card', "0")}>
                                        <div className="row">
                                            <div className="col-2 text-center">
                                            <img alt="" style={{width: '46px'}} className="grey-icon-v2" src={cardIcon}/>
                                            </div>
                                            <div className="col-10">
                                            Credit or Debit Card
                                            <p className="small small-grey-m0">
                                            Pay later and earn rewards
                                            </p>
                                            <div className="card-brands accordion-right-corner">
                                            <PaymentMethodsAccepted/>
                                            </div>
                                            </div>
                                        </div>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <PaymentMethodCCForm history={this.props.history} config={this.props.pconfig}/>
                                    </Card.Body>
                                    </Accordion.Collapse>
                                    </Card>
                                    }
                                    </Accordion>
                                    { (!this.props.vTerminal.hasCards && !this.props.vTerminal.hasECheck ) &&  
                                        <Message message="Warning: Select at least a Payment Method." />
                                    }

                                    { (!this.props.vTerminal.isAutopay && (this.props.vTerminal.getPaymentMethod === "card" || this.props.vTerminal.getPaymentMethod === "ach")) &&
                                    <div className="icheck-primary">
                                    <input type="checkbox" defaultChecked={this.props.vTerminal.paymentPage.paymentMethods.savePaymentMethodForFuture} id="savePaymentMethod" onClick={(e) => this.savePaymentMethodForFuture(e)} />
                                    <label htmlFor="savePaymentMethod">Save payment details for future use</label>
                                    </div>
                                    }
                                </li>

                                <li id="blocknotes" className="mb-4" style={{position: "relative"}}>
                                    <label className="optionalLabelInfo" style={{top: "46px", fontSize: "11px"}}>(Optional)</label>
                                    <h6 className="mb-3">Notes</h6>
                                    <textarea onChange={(e)=>this.handleNotesTextChange(e)} placeholder={this.props.vTerminal.paymentPage.notes.placeholder} style={{height: '75px'}} className={this.props.vTerminal.getPaymentPageErrors.notesError ? "form-control input-error" : "form-control"}></textarea>
                                </li>

                                <div className="bottom-fixed">
                                <li className="ui-state-default dragable-panel" id="blockreview">
                                    <div className="review-total">
                                        <h6 className="sub-header-line mb-3">Review & Send Payment</h6>
                                        <div className="row">
                                        <div className="col">Net Amount:</div>
                                        <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['netAmount']).toFixed(2))}</div>
                                        </div>
                                        <div className="row">
                                        <div className="col">Fee:</div>
                                        <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['fee']).toFixed(2))}</div>
                                        </div>

                                        <div className="row total-amount">
                                        <div className="col">Total Amount:</div>
                                        <div className="col">${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['totalAmount']).toFixed(2))}</div>
                                        </div>
                                    </div>
                                </li>

                                <li className="ui-state-default dragable-panel" id="blockpaymentButton">
                                    <div className="btn-group full-w">
                                        <button type="button" onClick={(e) => this.reviewPayment()} className="btn btn-success btn-lg">{ this.props.pconfig && this.props.pconfig.buttonLabelInModal ? this.props.pconfig.buttonLabelInModal : 'Review & Pay'} ${this.props.global.numberWithCommas(parseFloat(this.props.vTerminal.totalAmount['totalAmount']).toFixed(2))}</button>
                                    </div>
                                </li>
                                </div>
                                </ul>
                
           
    
            </div>
           
        )
    }
}

export { VirtualTerminal };